import { divisionIndicator } from "./divisionIndicator";
import { sectorIndicator } from "./sectorIndicator";
import { indicatorType } from "./types";

const indicators: indicatorType[] = [
  {
    label: "Etablissement",
    key: "center",
    endpoint: "/centers/",
    image: require("../../assets/images/indicator/institution-start-home-establishment-svgrepo-com.png"),
  },
  divisionIndicator,
  {
    label: "Formateurs",
    key: "former",
    endpoint: "/formers/",
    image: require("../../assets/images/indicator/teacher-male-svgrepo-com.png"),
  },
  {
    label: "Apprenants",
    key: "leaner",
    endpoint: "/leaners/",
    image: require("../../assets/images/indicator/scholar-svgrepo-com.png"),
  },
  {
    label: "Livres",
    key: "book",
    endpoint: "/books/",
    image: require("../../assets/images/indicator/book-library-ui-svgrepo-com.png"),
  },
];

indicators.push(sectorIndicator);

export { indicators };
