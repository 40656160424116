import React from "react";
import Chart from "react-apexcharts";
import {
  colorAccent,
  colorDanger,
  colorPrimary,
  colorSecondary,
} from "../../utils/themes/colors";
import https from "../../services/apiConfig";
import http from "../../services/authConfig";
import divisionService from "../../services/divisionService";
import { indicators } from "../../utils/helpers/indicators";
import { isEmpty } from "lodash";
import { valueIndicatorType } from "../../utils/helpers/types";
import { Button, Card, Spin } from "antd";

export function DataChart(props: {
  chartType?: any;
  type: string;
  indicators: valueIndicatorType[];
  selections: any[];
  onQuit?: () => void;
}) {
  const [labels, setLabels] = React.useState<string[]>([]);
  const [series, setSeries] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const onInit = React.useCallback(async () => {
    setLoading(true);
    const item = indicators.find((p) => p.key === props.type);
    let selections: any[] = [];
    let names: string[] = [];
    let values: any[] = [];
    if (!isEmpty(item) && props.selections.length === 0) {
      const url = item.endpoint;
      const v = await getData(url);
      v.data.forEach((p) => {
        selections.push(p.id);
      });
    } else if (props.selections.length > 0) {
      selections = [...props.selections];
    } else {
      //go out
    }
    await Promise.all(
      props.indicators.map(async (p) => {
        const vs: any[] = [];
        const lbs: any[] = [];
        await Promise.all(
          selections.map(async (q) => {
            const v = await getData(`${p.search}${q}`);
            const l = await getUniqueData(`${item?.endpoint}/${q}`);
            vs.push(v.total);
            lbs.push(l.name ?? "");
          })
        );
        values.push({ name: p.label ?? "", data: vs });
        names = [...lbs];
      })
    );
    setLabels(names);
    setSeries(values);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    onInit();
  }, []);

  async function getData(url: string) {
    let result: { data: any[]; total: number } = { data: [], total: 0 };
    await https
      .get(url)
      .then((response) => {
        const d = divisionService.getData(response);
        const total = divisionService.getTotal(response);
        result = { data: d, total };
      })
      .catch(() => {});
    return result;
  }

  async function getUniqueData(url: string) {
    let result: any = {};
    await https
      .get(url)
      .then((response) => {
        result = response.data;
      })
      .catch(() => {});
    return result;
  }

  return (
    <Card hoverable style={{minHeight:"100vh",margin:"10px auto"}}>
      <div style={{height:"100%"}}>
        <Spin spinning={loading}>
          {props.chartType !== "table" && (<Chart
            type={
              props.chartType === "table" ? "area" : props.chartType ?? "area"
            }
            options={{
              chart: {
                type:
                  props.chartType === "table"
                    ? "area"
                    : props.chartType ?? "area",
                stacked: true,
              },
              colors: [colorPrimary, colorAccent, colorDanger, "#AAFF00"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "monotoneCubic",
              },
              fill: {
                type: "gradient",
                gradient: {
                  opacityFrom: 0.9,
                  opacityTo: 0.8,
                },
              },
              legend: {
                position: "top",
                horizontalAlign: "left",
              },
              xaxis: {
                //type: 'datetime'
              },
              labels,
            }}
            series={series}
          />)}
          {(props.chartType === "table" && !loading) && (
            <table className="__table">
                <thead>
                    <tr>
                        <td>{`Division`}</td>
                        {series.map((p,i)=>(
                            <td key={`td_header_${i}`}>{p.name}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {labels.map((p,i)=>(
                        <tr key={`item_${i}`}>
                            <td>{p}</td>
                            {series.map((q,j)=>(
                                <td key={`td_body_${i}_${j}`}>
                                    {q.data[i] ?? 0}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
          )}
        </Spin>
      </div>

      <div style={{textAlign:"center",padding:10}}>
      <Button onClick={props.onQuit}>{`Quitter`}</Button>
      </div>
    </Card>
  );
}
