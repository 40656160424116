import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CenterHelper from "../../utils/helpers/centerHelper";
import { isArray, isNumber, sumBy, toNumber } from "lodash";
import { centerMeta } from "../../utils/helpers/meta";
import {
  tab1,
  tabDocuments,
  tabEquipments,
  tabLearnerManuals,
  tabLocalCounts,
  tabNatureAndGeneralStates,
  tabRepartitionAdmin,
} from "../../screens/center/sheet/table2";
import { getNumber } from "../../utils/helpers/title";
import { ItemView } from "./center/itemView";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "15px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
});

export function CenterIdentificationPDF() {
  const [identifications, setIndentifications] = React.useState<any[]>([]);
  const [infos, setInfos] = React.useState<any[]>([]);
  const [show, setShow] = React.useState<boolean>(false);
  const plug = useSelector((state: RootState) => state.center.plug);
  const [centerItem, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({}, "centerPlugMetas")
  );
  const [list1, setList1] = React.useState<any[]>([]);
  const [list2, setList2] = React.useState<any[]>([]);
  const [list3, setList3] = React.useState<any[]>([]);
  const [list4, setList4] = React.useState<any[]>([]);
  const [list5, setList5] = React.useState<any[]>([]);
  const [list6, setList6] = React.useState<any[]>([]);
  const [list7, setList7] = React.useState<any[]>([]);
  const [list8, setList8] = React.useState<any[]>([]);
  const [list9, setList9] = React.useState<any[]>([]);

  const center = React.useMemo(() => centerItem, [centerItem]);

  React.useEffect(() => {
    const c = new CenterHelper(plug, "centerPlugMetas");
    setCenter(c);
    getList1(c.getMetaValue("dssCenterSheetClassrooms", false, plug) ?? []);
    getList2(
      c.getMetaValue(
        centerMeta.dssCenterSheetTrainingStaffDistributions.metaKey,
        false,
        plug
      ) ?? []
    );
    getList3(
      c.getMetaValue(
        centerMeta.dssCenterSheetAdministrativeStaffDistributions.metaKey,
        false,
        plug
      ) ?? []
    );
    setList4(
      c.getMetaValue(
        centerMeta.dssCenterSheetPremisesStateNumbers.metaKey,
        false,
        plug
      ) ?? []
    );
    setList5(
      center.getMetaValue(
        centerMeta.dssCenterSheetPremisesNatureNumbers.metaKey,
        false,
        plug
      ) ?? []
    );
    setList6(
      c.getMetaValue(
        centerMeta.dssCenterSheetNatureAndGeneralStates.metaKey,
        false,
        plug
      ) ?? []
    );
    setList7(
      c.getMetaValue(
        centerMeta.dssCenterSheetEquipmentNumbers.metaKey,
        false,
        plug
      ) ?? []
    );
    setList8(
      c.getMetaValue(
        centerMeta.dssCenterSheetEquipmentNumbers.metaKey,
        false,
        plug
      ) ?? []
    );
    setList9(
      c.getMetaValue(
        centerMeta.dssCenterSheetManagementDocuments.metaKey,
        false,
        plug
      ) ?? []
    );
  }, [plug]);

  function getPresentation() {
    const elements: any[] = [];
    elements.push({
      label: "1.1. Nom du centre : ",
      value: plug.name,
    });
    elements.push({
      label: "CFP : ",
      value: center.getMetaValue("cfp", false, plug),
      sub: true,
      strong: true,
    });
    elements.push({
      label: "CAP : ",
      value: center.getMetaValue("cap", false, plug),
      sub: true,
      strong: true,
    });
    elements.push({
      label: "1.2. Adresse centre : ",
      value: plug.address,
    });
    elements.push({
      label: "1.3. Téléphone du centre : ",
      value: plug.phoneNumber,
    });
    elements.push({
      label: "1.4. Courriel du centre : ",
      value: plug?.email,
    });
    elements.push({
      label: "1.5. Boite postal du centre : ",
      value: center.getMetaValue("postalCode", false, plug),
    });
    elements.push({
      label: "1.6. Localisation administrative : ",
      value: "",
    });
    elements.push({
      label: "Province : ",
      value: center.getMetaValue("province", false, plug),
      sub: true,
    });
    elements.push({
      label: "District : ",
      value: center.getMetaValue("district", false, plug),
      sub: true,
    });
    elements.push({
      label: "Territoire/Commune : ",
      value: center.getMetaValue("commune", false, plug),
      sub: true,
    });
    elements.push({
      label: "1.7. Type de centre : ",
      value: center.getMetaValue("gender", false, plug),
    });

    elements.push({
      label: "1.8. Milieu : ",
      value: "",
    });
    elements.push({
      label: "1.9. Régime de gestion : ",
      value: center.getMetaValue("regime", false, plug),
    });
    elements.push({
      label:
        "1.10. N° de Référence juridique (Arrêté d’agrément /d’autorisation) ",
      value: "",
    });
    elements.push({
      label: "1.11. Nom et post nom du Chef de centre : ",
      value: plug.leader,
    });
    elements.push({
      label: "1.12. Téléphone du chef de Centre : ",
      value: plug.leaderPhone,
    });
    elements.push({
      label: "1.13. Le centre organise-t-il des formations modulaires ?",
      value:
        center.getMetaValue("modularForms", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    if (center.getMetaValue("modularForms", false, plug) === "yes") {
      elements.push({
        label: "dans quel domaine ",
        value: center.getMetaValue("domain", false, plug),
        sub: true,
      });
    }
    elements.push({
      label: "La durée de la formation : ",
      value: center.getMetaValue("duration", false, plug),
      sub: true,
    });
    setIndentifications(elements);
  }

  function getInfos() {
    const elements: any[] = [];
    elements.push({
      label: "2.1. Un point d’eau ?",
      value:
        center.getMetaValue("waterPointExist", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    if (center.getMetaValue("waterPointExist", false, plug) === "yes") {
      elements.push({
        label: "A préciser",
        value: center.getMetaValue("waterPoint", false, plug),
        sub: true,
        strong: true,
      });
    }
    elements.push({
      label: "2.2. Electricité ?",
      value:
        center.getMetaValue("electricity", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    elements.push({
      label: "2.3. Latrines ?",
      value:
        center.getMetaValue("latrine", false, plug) === "yes" ? "Oui" : "Non",
    });
    elements.push({
      label: "2.4. Cour de récréation ? ",
      value:
        center.getMetaValue("playground", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    elements.push({
      label: "2.5. Terrain de sport ? ",
      value:
        center.getMetaValue("sportsField", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    elements.push({
      label: "2.6. Clôture ?",
      value:
        center.getMetaValue("fence", false, plug) === "yes" ? "Oui" : "Non",
    });
    if (center.getMetaValue("fence", false, plug) === "yes") {
      elements.push({
        label: "Précision : ",
        value: center.getMetaValue("fenceNature", false, plug),
        sub: true,
      });
    }

    elements.push({
      label: "2.7. Les locaux sont-ils utilisés par un 2e centre ? ",
      value:
        center.getMetaValue("otherEtsInLocal", false, plug) === "yes"
          ? "Oui"
          : "Non",
    });
    if (center.getMetaValue("otherEtsInLocal", false, plug) === "yes") {
      elements.push({
        label: "le nom du Centre ",
        value: center.getMetaValue("otherEtsInLocalName", false, plug),
        sub: true,
      });
    }

    elements.push({
      label:
        "2.8. Langues utilisées : (Cochez une ou plusieurs langues utilisées) : ",
      value: center.getMetaValue("languages", true, plug),
    });
    setInfos(elements);
  }

  React.useEffect(() => {
    getPresentation();
    getInfos();
  }, [plug, center]);
  function getList1(list: any) {
    let result: any[] = [];
    if (isArray(list)) {
      result = [...list];
      if (result.length > 0) {
        result.push({
          level: "TOTAL",
          openClassroomCount: sumBy(
            list,
            (p) => toNumber(p.openClassroomCount) ?? 0
          ),
          current_year_gf: sumBy(list, (p) => getNumber(p.current_year_gf)),
          current_year_f: sumBy(list, (p) => getNumber(p.current_year_f)),
          last_year_gf: sumBy(list, (p) => getNumber(p.last_year_gf)),
          last_year_f: sumBy(list, (p) => getNumber(p.last_year_f)),
        });
      }
    }
    setList1(result);
    return result;
  }

  function getList2(list: any) {
    let result: any[] = [];
    if (isArray(list)) {
      result = [...list];
      if (result.length > 0) {
        result.push({
          level: "TOTAL",
          hf: sumBy(list, (p) => {
            return p.hf;
          }),
          f: sumBy(list, (p) => p.f),
        });
      }
    }
    setList2(result);
    return result;
  }

  function getList3(list: any) {
    let result: any[] = [];
    if (isArray(list)) {
      result = [...list];
      if (result.length > 0) {
        result.push({
          level: "TOTAL",
          center_hf: sumBy(list, (p) => getNumber(p.center_hf)),
          center_f: sumBy(list, (p) => getNumber(p.center_f)),
          atelier_hf: 0,
          atelier_f: 0,
          personnel_hf: 0,
          personnel_f: 0,
          ouvrier_hf: 0,
          ouvrier_f: 0,
        });
      }
    }
    setList3(result);
    return result;
  }
  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DSS" creator="DEV">
            <Page size="A4" style={styles.page}>
              <Header />
              <Text style={{ fontSize: 11, paddingLeft: 20, paddingTop: 25 }}>
                {`ANNEE DE FORMATION : ${center.getMetaValue(
                  "year1",
                  false,
                  plug
                )}- ${center.getMetaValue("year2", false, plug)}`}
              </Text>
              <View>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: "black",
                    textTransform: "uppercase",
                    paddingTop: 12,
                  }}
                >{`Centre de formation professionnelle OU `}</Text>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 12,
                    fontWeight: "black",
                    textDecoration: "underline",
                    textTransform: "uppercase",
                  }}
                >{`d’apprentissage professionnel`}</Text>
              </View>
              <View style={{ padding: "20px 50px" }}>
                <TitleView title="I. IDENTIFICATION DU CENTRE" />
                {identifications.map((p, i) => (
                  <ItemView
                    label={p.label}
                    strong={p.strong == true}
                    value={p.value}
                    sub={p.sub == true}
                    key={`identification_${i}`}
                  />
                ))}
                <TitleView title="II. INFORMATIONS GENERALES SUR LE CENTRE" />
                {infos.map((p, i) => (
                  <ItemView
                    label={p.label}
                    strong={p.strong == true}
                    value={p.value}
                    sub={p.sub == true}
                    key={`identification_${i}`}
                  />
                ))}
              </View>
            </Page>
            <Page size="A4" style={styles.page}>
              <View style={{ padding: "20px 50px" }}>
                <TitleView title="III. NOMBRE DE SALLES, EFFECTIFS DES APPRENANTS ET REDOUBLANTS PAR NIVEAU D’ETUDE ET PAR SEXE" />
                <TableView
                  columns={[
                    { title: "Niveau de formation", dataIndex: "level" },
                    {
                      title: "Nombre de salles de cours ouvertes",
                      dataIndex: "openClassroomCount",
                    },
                    {
                      title: "Apprenants Inscrits en 2022-2023",
                      dataIndex: "",
                      children: [
                        { title: "GF", dataIndex: "current_year_gf" },
                        { title: "F", dataIndex: "current_year_f" },
                      ],
                    },
                    {
                      title: "Redoublants apprenants de 2021-2022",
                      dataIndex: "",
                      children: [
                        { title: "GF", dataIndex: "last_year_gf" },
                        { title: "F", dataIndex: "last_year_f" },
                      ],
                    },
                  ]}
                  list={list1}
                />
                <TitleView title="IV. REPARTITION DU PERSONNEL FORMATEUR PAR QUALIFICATION ET PAR SEXE" />
                <TableView
                  columns={[
                    { title: "", dataIndex: "level" },
                    { title: "HF", dataIndex: "hf" },
                    { title: "F", dataIndex: "f" },
                  ]}
                  list={list2}
                />
                <TitleView title="V. REPARTITION DU PERSONNEL ADMINISTRATIF ET OUVRIER PAR QUALIFICATION ET PAR SEXE" />
                <TableView columns={tabRepartitionAdmin} list={list3} />
                <TitleView title="VI. NOMBRE DE LOCAUX REPARTIS SELON LEURS ETATS" />
                <TableView columns={tabLocalCounts} list={list4} />
                <TitleView title="VII. NOMBRE DE LOCAUX REPARTIS SELON LEURS NATURES" />
                <TableView columns={tab1} list={list5} />
                <TitleView title="" />
                <TableView columns={tabNatureAndGeneralStates} list={list6} />
                <TitleView title="VIII. NOMBRE D’EQUIPEMENTS (Matériels) SELON LEUR ETAT ET LEURS TYPES" />
                <TableView columns={tabEquipments} list={list7} />
                <TitleView title="IX. NOMBRE DE MANUELS DE FORMATION MIS A LA DISPOSITION DES APPRENANTS" />
                <TableView columns={tabLearnerManuals} list={list8} />
                <TitleView title="X. NOMBRE DE DOCUMENTS DE GESTION ET GUIDE DE FORMATION" />
                <TableView columns={tabDocuments} list={list9} />
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header = () => {
  return (
    <View style={{ display: "flex", flexDirection: "row" }}>
      <View>
        <Image
          src={require("../../assets/images/flag-drc.png")}
          style={{ width: "68px" }}
        />
      </View>
      <View style={{ flex: 1 }}>
        <Text
          style={{ textAlign: "center", fontSize: 18, fontWeight: "ultrabold" }}
        >
          {`REPUBLIQUE DEMOCRATIQUE DU CONGO`}
        </Text>
        <Text style={{ textAlign: "center", fontSize: 11, fontWeight: "bold" }}>
          {`MINISTERE DE LA FORMATION PROFESSIONNELLE ET METIERS`}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 11,
            padding: 5,
            textDecoration: "underline",
            fontWeight: "black",
          }}
        >
          {`SECRETARIAT GENERAL`}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 18,
            padding: 5,
            fontWeight: "bold",
          }}
        >
          {`DIRECTION STATISTIQUES SCOLAIRES`}
        </Text>
        <Text
          style={{
            textAlign: "center",
            fontSize: 18,
            textDecoration: "underline",
            fontWeight: "bold",
          }}
        >
          {`DSS`}
        </Text>
      </View>
      <View>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "68px" }}
        />
      </View>
    </View>
  );
};

const TitleView: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};

const TableView: React.FC<{ columns: any[]; list: any }> = ({
  columns,
  list,
}) => {
  const [rows, setRows] = React.useState<any[]>([]);
  const [items, setItems] = React.useState<any[]>([]);

  React.useEffect(() => {
    const elements: any[] = [];
    columns.forEach((p) => {
      const children = p?.children ?? [];
      if (children.length > 0) {
        children?.forEach((q: any) => {
          elements.push({ title: q.title, dataIndex: q.dataIndex });
        });
      } else {
        elements.push({ title: p.title, dataIndex: p.dataIndex });
      }
    });
    setRows(elements);
    if (isArray(list)) {
      setItems(list);
    } else {
    }
  }, [list, columns]);

  function getRowTitle(dataIndex: string, item: any) {
    const title = item[dataIndex] ?? "";
    return title;
  }

  return (
    <>
      <View style={[styles.tableRow]}>
        {columns.map((p, i) => (
          <View key={`tab_${i}`} style={[styles.rowItem, styles.heading]}>
            <Text
              style={{
                fontFamily: "calibri-bold",
                fontSize: 11,
                textAlign: "center",
                color: "#3e3e3e",
              }}
            >
              {p.title}
            </Text>
            {p?.children?.length > 0 && (
              <View
                style={[
                  styles.tableRow,
                  { borderTop: "1px solid #f7f7f7", borderBottom: "none" },
                ]}
              >
                {/**@ts-ignore */}
                {p?.children?.map((x, j) => (
                  <View
                    key={`sub_tab_${j}`}
                    style={[styles.rowItem, styles.heading, { padding: 0 }]}
                  >
                    <Text
                      style={{
                        fontFamily: "calibri-bold",
                        fontSize: 11,
                        textAlign: "center",
                        color: "#3e3e3e",
                      }}
                    >
                      {x.title}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
        ))}
      </View>
      {items.map((item, index) => {
        return (
          <View style={[styles.tableRow]} key={`row_${index}`}>
            {columns.map((p, i) => (
              <View key={`tab_${i}_${index}`} style={[styles.rowItem]}>
                {p?.children?.length > 0 ? (
                  <View style={[styles.tableRow, { borderBottom: "none" }]}>
                    {/**@ts-ignore */}
                    {p?.children?.map((x, j) => (
                      <View
                        key={`sub_tab_${j}`}
                        style={[styles.rowItem, { padding: 0 }]}
                      >
                        <Text
                          style={{
                            fontFamily: "calibri-light",
                            fontSize: 11,
                            textAlign: "center",
                            color: "#000",
                          }}
                        >
                          {getRowTitle(x.dataIndex, item)}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : (
                  <Text
                    style={{
                      fontFamily: "calibri-light",
                      fontSize: 11,
                      textAlign: "center",
                      color: "#000",
                    }}
                  >
                    {getRowTitle(p.dataIndex, item)}
                  </Text>
                )}
              </View>
            ))}
          </View>
        );
      })}
    </>
  );
};
