import { Button, Card, List } from "antd";
import React from "react";
import { isEmpty } from "lodash";
import { indicators } from "../../utils/helpers/indicators";
import { chartTypes } from "../../utils/charts";

export function DiagramStep(props: {
  setType: (v: string) => void;
  type?: string;
}) {
  function onChoice(v: any) {
    props.setType(v);
  }

  const renderItem = (item: any, index: number) => {
    return (
      <List.Item onClick={() => onChoice(item.type)}>
        <Card
          hoverable
          style={{ border: props.type === item.type ? "solid 2px blue" : "" }}
          cover={
            <img
              style={{ height: 150, objectFit: "contain" }}
              src={item.image}
            />
          }
        >
          <Card.Meta title={item?.label} />
        </Card>
      </List.Item>
    );
  };

  return (
    <section>
      <List
        renderItem={renderItem}
        dataSource={chartTypes}
        grid={{
          column: 4,
          lg: 4,
          xs: 1,
          gutter: 32,
          md: 2,
        }}
      />
    </section>
  );
}
