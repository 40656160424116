import { Button, notification } from "antd";
import { useFormik } from "formik";
import React from "react";
import { DillInput, DillPassword } from "../components/input";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/title";
import userService from "../services/userService";
import userHelper from "../utils/helpers/userHelper";
import { useDispatch } from "react-redux";
import authService from "../services/authService";
import { isEmpty, toNumber } from "lodash";

export function LoginScreen() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: onLogin,
  });

  async function onLogin() {
    const { password, username } = formik.values;
    const data: any = { password, username };
    setLoading(true);
    await userService
      .login(data)
      .then(async (response) => {
        const result = response.data;
        let id = result?.data?.roles?.id;
        const token = result?.token;
        id = `${id}`;
        if (!isEmpty(id) && !isEmpty(token)) {
          const user = await getUser(id ?? "");
          authService.setToken(token);
          await userHelper.setCurrentUser(user, dispatch);
          //window.location.href = "/admin";
          navigate('/admin/divisions');
        } else {
          formik.setErrors({
            password: "Veuillez vérfier peut-être le mot de passe",
            username: "Veuillez vérifier peut-être l'adresse E-mail",
          });
          setLoading(false);
        }
      })
      .catch((reason) => {
        const error = userService.getError(reason);
        console.log("Error", reason?.response ?? reason);
        notification.error({
          message: "Authentification",
          description: "Les informations d'identification invalides.",
        });
        setLoading(false);
      });
  }

  async function getUser(id: string) {
    let result = null;
    /**@ts-ignore */
    if (isEmpty(`${id}`) && !(id > 0)) {
      return result;
    }
    await userService
      .get(id)
      .then((response) => {
        result = response.data;
      })
      .catch((reason) => {
        const error = userService.getError(reason);
      });
    return result;
  }

  function handleLogin() {
    formik.handleSubmit();
  }
  return (
    <React.Fragment>
      <section className="box" style={{
        background:`linear-gradient(#000A,#56AE),url(${require('../assets/images/graphics/blank.jpg')})`,height:"100vh"
      }}>
        <div className="card box__login">
          <TitleComponent title="Connexion" type="creation"></TitleComponent>
          <div className="card">
            <DillInput
              formik={formik}
              name="username"
              label={"Adresse E-mail"}
              placeholder={"your@domain"}
              icon="fa-solid fa-user"
              type="email"
            />
            <DillPassword
              formik={formik}
              label="Mot de passe"
              placeholder="Mot de passe"
              name="password"
              icon="fa-solid fa-key"
            />
            <div
              className="row"
              style={{
                alignItems: "center",
                justifyContent: "center",
                padding: "10px 0 0",
              }}
            >
              <Button
                loading={loading}
                onClick={handleLogin}
                disabled={loading}
                type="primary"
              >{`Se connecter`}</Button>
            </div>
          </div>
          <p style={{fontWeight:"lighter",color:"#FFF",textAlign:"justify",padding:4}}>
            {`La DSS joue un rôle crucial dans l'élaboration des politiques de formation professionnelle en RDC. Grâce à ses activités de collecte et d'analyse de données, elle fournit des informations essentielles pour orienter les décisions politiques et stratégiques, améliorer la qualité de la formation, et répondre aux besoins du marché du travail. Elle contribue également à la transparence et à la redevabilité du système de formation professionnelle en fournissant des données fiables et actualisées.`}
          </p>
        </div>
        <div style={{position:"fixed",bottom:16,right:16,background:"#FFF"}}>
          <img style={{display:"block",objectFit:"contain",width:120}} src={require('../assets/images/min.png')} alt="" />
        </div>
      </section>
    </React.Fragment>
  );
}
