import { Button, Layout, Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export function NotFoundScreen(props:{layout?:"admin"|"home"}) {
  const days = 0;
  return (
    <Layout
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: `linear-gradient(#000F,#111,#111F,#000,#000E),url(${require('../assets/images/min.png')})`,
        backgroundSize:'contain',
        backgroundRepeat:'no-repeat',
      }}
      className={`main ${props.layout!=='home'?'yc_container_main':''}`}
    >
      <Result
        //status={days > 0 ? "404" : "403"}
        title={""}
        subTitle={days > 0 ? "" : ""}
        style={{ color: "#FFF" }}
        icon={(
          <div style={{textAlign:"center",display:"flex",fontSize:16,gap:10,alignItems:"center"}}>
            <div style={{color:"#F7F7F7",padding:15,borderRight:`solid .2px #AAA7`}}>
              <span style={{fontSize:64,fontWeight:"bolder"}}>404</span>
            </div>
            <div>
              <span>{`cette page est introuvable`}</span>
            </div>
            <img src={require('../assets/images/min.png')} alt="" style={{display:"none"}} />
          </div>
        )}
        extra={(
          <div>
            <Button type="link">
              <Link to='/'>
                {`Retourner au site`}
              </Link>
            </Button>
          </div>
        )}
      />
    </Layout>
  );
}
