import { Spin, Typography } from "antd";
import { isEmpty } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { mapMarker } from "../utils/helpers/title";
import centerService from "../services/centerService";
import { calculateCenter } from "../utils/helpers/geoHelper";
import { DivisionMap } from "../components/map";
import { MapComponent } from "../components/map/component";

export function MapScreen() {
  const [markers, setMarkers] = React.useState<mapMarker[]>([]);
  const [coordinates, setCoordinates] = React.useState<
    { lat: number; lng: number; item?: any }[]
  >([]);
  const [loading, setLoading] = React.useState(true);
  const [center, setCenter] = React.useState<
    google.maps.LatLngLiteral | undefined
  >();

  async function init() {
    setLoading(true);
    const list: { lat: number; lng: number; item?: any }[] = [];
    await centerService
      .getByKey(``)
      .then((response) => {
        const data = centerService.getData(response);
        data.forEach((p) => {
          let { latitude, longitude, name } = p;
          if (latitude === 0 || longitude === 0) {
            latitude = null;
            longitude = null;
          }
          if (latitude && longitude && !isEmpty(name)) {
            list.push({
              lat: latitude,
              lng: longitude,
              item: p,
            });
          }
        });
        console.log('LIST',list);
        /*if (list.length === 1) {
          setCenter(list[0].position);
        } else {
          const coordinates: google.maps.LatLngLiteral[] = [];
          list.forEach((p) => {
            coordinates.push(p.position);
          });
          const c: google.maps.LatLngLiteral = calculateCenter(coordinates);
          setCenter(c);
        }
        setMarkers(list);*/
        setCoordinates(list);
      })
      .catch((reason) => {});
    setLoading(false);
  }

  React.useEffect(() => {
    init();
  }, []);

  return (
    <main className="main main yc_container_main">
      {/*!loading && (
        <DivisionMap
          markers={markers}
          zoom={isEmpty(center) ? 6 : 13}
          center={center}
        />
      )*/}
      <Spin spinning={loading}>
        <MapComponent data={coordinates} />
      </Spin>
    </main>
  );
}
