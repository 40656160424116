import { routeInterface } from "../routes";
import { SheetScreen } from "../screens/sheet";
import { PDFFileScreen } from "../screens/pdf";
import { PlugListScreen } from "../screens/plugs";
import { LearnerPDFFileScreen } from "../screens/pdfLearner";
import { GraphicScreen } from "../screens/graphics";
import { CreateIndicatorScreen } from "../screens/graphic/create";
import { ShowGraphicScreen } from "../screens/graphic/show";
export const dssRoutes: routeInterface[] = [
  {
    component: PlugListScreen,
    icon: `fa-solid fa-flag`,
    isMenu: true,
    label: "Fiche DSS",
    layout: "/admin",
    name: "plug-view",
    path: "/plugs",
    subscribeable: false,
    // group: "dss",
    description: "Les Fiches DSS",
  },
  {
    component: SheetScreen,
    icon: `fa-thin fa-flag`,
    isMenu: false,
    label: "Saisi de fiche DSS",
    layout: "/admin",
    name: "sheet-view",
    path: "/sheets",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  {
    component: PDFFileScreen,
    icon: `fa-solid fa-flag`,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "sheet-movement-view",
    path: "/plug/:id",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  {
    component: LearnerPDFFileScreen,
    icon: `fa-solid fa-flag`,
    isMenu: false,
    label: "",
    layout: "/admin",
    name: "sheet-pdf-learner-view",
    path: "/plug/:id/learner",
    subscribeable: false,
    // group: "dss",
    description: "Fiches DSS",
  },
  {
    component: GraphicScreen,
    icon: `fa-solid fa-chart-scatter-bubble`,
    isMenu: true,
    label: "Graphiques",
    layout: "/admin",
    name: "graphic-screen",
    path: "/graphics",
    subscribeable: false,
    // group: "dss",
    description: "Graphiques",
  },
  {
    component: ShowGraphicScreen,
    icon: `fa-solid fa-chart-scatter-bubble`,
    isMenu: false,
    label: "Graphiques",
    layout: "/admin",
    name: "graphic-screen-view",
    path: "/graphics/:uid",
    subscribeable: false,
    // group: "dss",
    description: "Graphiques",
  },
  {
    component: CreateIndicatorScreen,
    icon: `fa-solid fa-chart-scatter-bubble`,
    isMenu: false,
    label: "Graphiques",
    layout: "/admin",
    name: "indicator-create-screen",
    path: "/graphics/create",
    subscribeable: false,
    // group: "dss",
    description: "Créateur d'indicateur",
  },
];
