import { Divider, List, Select, Space, Spin, Typography } from "antd";
import React from "react";
import https from "../../services/apiConfig";
import http from "../../services/authConfig";
import { indicators } from "../../utils/helpers/indicators";
import { isEmpty } from "lodash";
import divisionService from "../../services/divisionService";
import { DefaultOptionType } from "antd/es/select";
import { DillInput, DillTextarea } from "../../components/input";

export function DataStep(props: {
  type: string;
  selections: any[];
  setSelections: (v: any[]) => void;
  formik?:any;
}) {
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);

  const onInit = React.useCallback(async () => {
    setLoading(true);
    const item = indicators.find((p) => p.key === props.type);
    if (!isEmpty(item)) {
      const url = item.endpoint;
      const v = await getData(url);
      const options: DefaultOptionType[] = [];
      v.data.forEach((p) => {
        options.push({
          label: p.name,
          value: `${p.id}`,
        });
      });
      console.log("PP", options);
      setData(options);
    }
    setLoading(false);
  }, [props.type]);

  async function getData(url: string) {
    let result: { data: any[]; total: number } = { data: [], total: 0 };
    await https.get(url).then((response) => {
      const d = divisionService.getData(response);
      const total = divisionService.getTotal(response);
      result = { data: d, total };
    });
    return result;
  }

  React.useEffect(() => {
    onInit();
  }, [props.type]);

  function handleChange(v: any) {
    props.setSelections(v);
  }

  return (
    <div style={{ padding: "30px 10px", width: "100%" }}>
      <>
        <Typography.Title
          level={2}
          style={{ fontSize: 20, textAlign: "center" }}
        >
          {`Choisissez les données à utiliser`}
        </Typography.Title>
        <span>
          {`Si vous ne choisissez aucune informations, nous considérerons toutes les informations existants dans la base de données.`}
        </span>
        <Divider />
        <Select
          defaultValue={[]}
          value={props.selections}
          optionFilterProp="label"
          onChange={handleChange}
          style={{ width: "100%" }}
          mode="multiple"
          key={"selections"}
          loading={loading}
          options={data}
        />
        <Divider />
        <span style={{textAlign:"center",fontWeight:"bold",display:"inline-block",width:"100%"}}>{`Description de l'indicateur`}</span>
        <DillInput formik={props.formik} label="Titre de l'indicateur" name="title" />
        <DillTextarea formik={props.formik} label="Note" name="note" />
      </>
    </div>
  );
}
