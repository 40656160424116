import { indicatorType } from "./types";

export const divisionIndicator: indicatorType = {
  label: "Province éducationnelle",
  key: "division",
  endpoint: "divisions",
  image: require("../../assets/images/indicator/globe-2-svgrepo-com.png"),
  properties: ["name"],
  values: [
    {
      label: "Nombre de sous province éducationnelle",
      key: "sub_division",
      type: "totalCount",
      search: "sub_divisions?division=",
    },
    {
      label: "Nombre de centre pour chaque province éducationnelle",
      key: "center_length",
      type: "totalCount",
      search: "centers?subDivision.division=",
    },
    {
      label: "Nombre d'apprenants",
      key: "learners",
      type: "totalCount",
      search: "leaners?center.subDivision.division=",
    },
    {
      label: "Nombre de formateurs",
      key: "former",
      type: "totalCount",
      search: "formers?center.subDivision.division=",
    },
  ],
};
