import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CenterHelper from "../../utils/helpers/centerHelper";
import { isArray, isNumber, sumBy, toNumber } from "lodash";
import { centerMeta } from "../../utils/helpers/meta";
import {
  tab1,
  tabDocuments,
  tabEquipments,
  tabLearnerManuals,
  tabLocalCounts,
  tabNatureAndGeneralStates,
  tabRepartitionAdmin,
} from "../../screens/center/sheet/table2";
import { getNumber } from "../../utils/helpers/title";
import { ItemView } from "./center/itemView";
import dateHelpers from "../../utils/helpers/dateHelpers";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "15px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
});

export function LearnerIdentificationPDF() {
  const [identifications, setIndentifications] = React.useState<any[]>([]);
  const [infos, setInfos] = React.useState<any[]>([]);
  const [show, setShow] = React.useState<boolean>(false);
  const plug = useSelector((state: RootState) => state.center.plug);
  const [centerItem, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({}, "learnerPlugMetas")
  );

  const center = React.useMemo(() => centerItem, [centerItem]);

  React.useEffect(() => {
    const c = new CenterHelper(plug, "learnerPlugMetas");
    setCenter(c);
  }, [plug]);

  function getPresentation() {
    const elements: any[] = [];
    elements.push({
      label: "Nom : ",
      value: plug.lastName,
    });
    elements.push({
      label: "Post-nom : ",
      value: plug.postName,
    });
    elements.push({
      label: "Prénom :",
      value: plug.firstName,
    });
    elements.push({
      label: "Sexe :",
      value: center.getMetaValue("sex", false, plug),
    });
    elements.push({
      label: "Lieu de naissance :",
      value: plug.birthPlace,
    });
    elements.push({
      label: "Date de naissance :",
      value: plug?.birthDate
        ? dateHelpers.getDate(plug?.birthDate, "DD MMM YYYY")
        : "",
    });
    elements.push({
      label: "Nationalité :",
      value: center.getMetaValue("nationality", false, plug),
    });
    elements.push({
      label: "Nom du père :",
      value: center.getMetaValue("fatherName", false, plug),
    });
    elements.push({
      label: "Profession du père :",
      value: center.getMetaValue("fatherWork", false, plug),
    });
    elements.push({
      label: "Nom de la mère :",
      value: center.getMetaValue("montherName", false, plug),
    });
    elements.push({
      label: "Profession de la mère :",
      value: center.getMetaValue("montherWork", false, plug),
    });
    elements.push({
      label: "Nom du tuteur :",
      value: center.getMetaValue("tutorName", false, plug),
    });

    elements.push({
      label: "N° Téléphone du Tuteur :",
      value: center.getMetaValue("tutorPhone", false, plug),
    });
    elements.push({
      label: "Profession du Tuteur :",
      value: center.getMetaValue("tutorWork", false, plug),
    });
    elements.push({
      label: "Adresse du Tuteur :",
      value: center.getMetaValue("tutorAddress", false, plug),
    });
    elements.push({
      label: "Province d'origine :",
      value: center.getMetaValue("province", false, plug),
    });
    elements.push({
      label: "Territoire d’origine :",
      value: center.getMetaValue("territory", false, plug),
    });
    elements.push({
      label: "Secteur d’origine :",
      value: center.getMetaValue("originSector", false, plug),
    });
    elements.push({
      label: "Village d’origine : ",
      value: center.getMetaValue("village", false, plug),
    });
    const languages = center.getMetaValue("languages", false, plug);
    let lang = ``;
    languages?.forEach((p: string, i: number) => {
      if (i > 0) {
        lang += ",";
      }
      lang += `${p}`;
    });
    elements.push({
      label: "Langues parlées :",
      value: lang,
    });
    elements.push({
      label: "N° Téléphone de l’apprenant :",
      value: plug.phone,
    });
    elements.push({
      label: "Adresse de l’apprenant :",
      value: plug.address,
    });
    elements.push({
      label: "Etat de santé de l’apprenant :",
      value: center.getMetaValue("health", false, plug),
    });
    elements.push({
      label: "Aptitudes intellectuelles de l’apprenant : ",
      value: center.getMetaValue("intellectualAptitude", false, plug),
    });
    elements.push({
      label: "Intérêts de l’apprenant : ",
      value: center.getMetaValue("interest", false, plug),
    });
    elements.push({
      label: "Local de cours de l’apprenant : ",
      value: center.getMetaValue("local", false, plug),
    });
    setIndentifications(elements);
  }

  React.useEffect(() => {
    getPresentation();
  }, [plug, center]);

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DSS" creator="DEV">
            <Page size="A4" style={styles.page}>
              <View
                style={{
                  padding: "20px 50px",
                  border: "3px ridge #000",
                  height: "100%",
                }}
              >
                <Header />
                <ItemView
                  label={"Centre: "}
                  value={center.getMetaValue("center", false, plug)}
                  key={`centre_value`}
                />
                <View
                  style={{ flexDirection: "row", gap: 4, alignItems: "center" }}
                >
                  <View style={{ flex: 1 }}>
                    <ItemView
                      label={"Niveau : "}
                      value={center.getMetaValue("level", false, plug)}
                      key={`level_value`}
                    />
                  </View>
                  <View style={{ flex: 1 }}>
                    <ItemView
                      label={"Option :"}
                      value={center.getMetaValue("option", false, plug)}
                      key={`option_value`}
                    />
                  </View>
                </View>
                {identifications.map((p, i) => (
                  <ItemView
                    label={p.label}
                    strong={p.strong == true}
                    value={p.value}
                    sub={p.sub == true}
                    key={`identification_${i}`}
                  />
                ))}
                <Text
                  style={{
                    textAlign: "right",
                    padding: 20,
                    fontSize: 11,
                    fontFamily: "calibri",
                  }}
                >
                  {`Fait à ${center.getMetaValue(
                    "province",
                    false,
                    plug
                  )}, le ${dateHelpers.getDate(plug.createdAt, "DD/MM/YYYY")}`}
                </Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header = () => {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Text
        style={{ textAlign: "center", fontSize: 11, fontWeight: "ultrabold" }}
      >
        {`République Démocratique du Congo`}
      </Text>
      <Text style={{ textAlign: "center", fontSize: 11, fontWeight: "bold" }}>
        {`Ministère de la Formation Professionnelle et Métiers`}
      </Text>
      <Image
        src={require("../../assets/images/armoirie.png")}
        style={{ width: "50px" }}
      />
      <Text
        style={{
          textAlign: "center",
          fontSize: 11,
          padding: 5,
          textDecoration: "none",
          fontWeight: "black",
          fontFamily: "calibri-bold",
        }}
      >
        {`DIRECTION STATISTIQUES SCOLAIRES`}
      </Text>
      <Text
        style={{
          textAlign: "center",
          fontSize: 11,
          padding: 5,
          textDecoration: "underline",
          fontWeight: "black",
          fontFamily: "calibri-bold",
        }}
      >
        {`Formulaire d'identification de l'Apprenant`}
      </Text>
    </View>
  );
};
