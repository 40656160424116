import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { TableView } from "./table";
import CenterHelper from "../../utils/helpers/centerHelper";
import { centerMeta } from "../../utils/helpers/meta";
import { ItemTabView } from "./building/item";
import { Identification } from "./building/identification";
import { TablePdfView } from "../tablePDF";
import { isArray, isEmpty } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { ItemView } from "./center/itemView";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

const bgColor = "#dcdcdc";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
  logoCountry: {
    fontFamily: "calibri-italic",
    fontSize: 11,
    textAlign: "center",
  },
  logoHeader: {
    fontFamily: "calibri-bold",
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
  },
  title1: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  },
  title2: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    //height:"auto",
    padding: "1px",
    flex: 1,
  },
  value1: {
    fontSize: 11,
    fontFamily: "calibri",
    padding: 1,
    minHeight: "15px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    color: "#000",
  },
  view1: {
    width: "15%",
    borderRight: "1px solid #000",
  },
  view2: {
    borderRight: "1px solid #000",
    height: "100%",
  },
});

export function EstablishmentPDF() {
  const plug = useSelector((state: RootState) => state.center.plug);
  const [center, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({})
  );
  const [headers, setHeaders] = React.useState<any[]>([]);
  const [list1, setList1] = React.useState<any[]>([]);
  const [list2, setList2] = React.useState<any[]>([]);
  const [list3, setList3] = React.useState<any[]>([]);
  const [list4, setList4] = React.useState<any[]>([]);
  const [element1, setElement1] = React.useState<any[]>([]);
  const [element2, setElement2] = React.useState<any[]>([]);
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const c = new CenterHelper(plug, "centerPlugMetas");
    setCenter(c);
    let elements = c.getMetaValue("sectors1", false, plug);
    setList1(isEmpty(elements) ? [] : elements);
    elements = c.getMetaValue("sectors2", false, plug);
    setList2(isEmpty(elements) ? [] : elements);
    elements = c.getMetaValue("sectorStats1", false, plug);
    setList3(isEmpty(elements) ? [] : elements);
    elements = c.getMetaValue("sectorStats2", false, plug);
    setList4(isEmpty(elements) ? [] : elements);
    const dt = c.getMetaValue("materialReceivedDate", false, plug);
    if (!isEmpty(dt)) {
      setDate(dateHelpers.getDate(dt, "dddd DD MMMM YYYY"));
    }
    setHeaderElements(c);
    setPart1(c);
    setPart2(c);
  }, [plug]);

  function setHeaderElements(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "PROVINCE :", value: plug.province });
    list.push({
      label: "PROV FORM  :",
      value: c.getMetaValue("subProvince", false, plug),
    });
    list.push({ label: "MATRICULE :", value: plug.matricule });
    list.push({
      label: "TELEPHONE :",
      value: c.getMetaValue("phoneNumber", false, plug),
    });
    const year1 = c.getMetaValue("year1", false, plug);
    const year2 = c.getMetaValue("year2", false, plug);
    if (!isEmpty(year1) && !isEmpty(year2)) {
      list.push({ label: "ANNEE DE FORMATION :", value: `${year1}-${year2}` });
    } else {
      list.push({ label: "ANNEE DE FORMATION :", value: `` });
    }
    setHeaders(list);
  }

  function setPart1(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "DENOMINATION: ", value: plug.name });
    list.push({
      label: "STATUT JURIDIQUE: ",
      value: c.getMetaValue("legalStatus", false, plug),
    });
    list.push({
      label: "TYPE DE GESTION: ",
      value: plug.regime,
    });
    list.push({
      label: "ARRETE D’AGR N°: ",
      value: c.getMetaValue("orderNumber", false, plug),
    });
    list.push({
      label: "NOTIFICATION: ",
      value: c.getMetaValue("notification", false, plug),
    });
    list.push({ label: "MATRICULE: ", value: plug.matricule });
    list.push({
      label: "COMMUNE/TERRITOIRE D’IMPLATATION :",
      value: c.getMetaValue("settlementMunicipality", false, plug),
    });
    list.push({
      label: "SOUS-DIVISION:",
      value: plug.subDivision,
    });
    list.push({
      label: "NOM DU PROMOTEUR:",
      value: c.getMetaValue("leader", false, plug),
    });
    list.push({
      label: "PROFESSION:",
      value: c.getMetaValue("leaderWork", false, plug),
    });
    list.push({
      label: "ANNEE DE CREATION:",
      value: c.getMetaValue("creationYear", false, plug),
    });
    setElement1(list);
  }

  function setPart2(c: CenterHelper) {
    let list: any[] = [];
    list.push({
      label: "AVENUE + NUMERO :",
      value: c.getMetaValue("street", false, plug),
    });
    list.push({
      label: "QUARTIER/VILLAGE :",
      value: c.getMetaValue("quarter", false, plug),
    });
    list.push({
      label: "COMMUNE/LOCALITE :",
      value: c.getMetaValue("locality", false, plug),
    });
    list.push({
      label: "POINT DE REPERE :",
      value: c.getMetaValue("landmark", false, plug),
    });
    list.push({
      label: "STATUT D’OCCUPATION DES LIEUX :",
      value: c.getMetaValue("occupancy", false, plug),
    });
    list.push({
      label: "ETABLISSEMENTS VOISINS (3)  :",
      value: c.getMetaValue("neighboringEstablishments", false, plug),
    });
    list.push({
      label: "QUARTIER :",
      value: c.getMetaValue("quarter", false, plug),
    });
    list.push({
      label: "COMMUNE :",
      value: c.getMetaValue("commune", false, plug),
    });
    list.push({
      label: "SOUS-DIVISION :",
      value: c.getMetaValue("subProvince", false, plug),
    });
    list.push({ label: "PROVINCE  :", value: plug.province });
    setElement2(list);
  }

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DSS" creator="DEV">
            <Page size="A4" style={styles.page}>
              <View>
                <View>
                  <Header list={headers} />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Text style={{ border: "3px solid #000", padding: "5px" }}>
                      {`FICHE DE RENSEIGNEMENT DE L’ETABLISSEMENT `}
                    </Text>
                  </View>
                  <TitleView
                    title="I.  IDENTIFICATION DE L’ETABLISSEMENT (ECOLE – CENTRE – ATELIER - GARAGE)"
                    key="part1"
                  />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "0",
                      width: 300,
                    }}
                  >
                    <ItemView label="TYPE:" value="dffd" />
                  </View>
                  <View style={{ padding: "0 20px" }}>
                    {element1.map((p, i) => (
                      <ItemView
                        label={p.label}
                        value={p.value}
                        key={`part1_${i}`}
                      />
                    ))}
                  </View>
                  <TitleView
                    title="II.  LOCALISATION DE L’ETABLISSEMENT (ECOLE-CENTRE-ATELIER-GARAGE)"
                    key="part2"
                  />
                  <View style={{ padding: "0 20px" }}>
                    {element2.map((p, i) => (
                      <ItemView
                        label={p.label}
                        value={p.value}
                        key={`part2_${i}`}
                      />
                    ))}
                  </View>
                  <View style={{ border: "1px solid #000", borderTop: "none" }}>
                    <TablePdfView
                      columns={[
                        { title: "FILIERES", dataIndex: "sector" },
                        {
                          title: "3 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "N/Local", dataIndex: "3_month_local" },
                            { title: "GF", dataIndex: "3_month_gf" },
                            { title: "F", dataIndex: "3_month_f" },
                          ],
                        },
                        {
                          title: "6 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "N/Local", dataIndex: "6_month_local" },
                            { title: "GF", dataIndex: "6_month_gf" },
                            { title: "F", dataIndex: "6_month_f" },
                          ],
                        },
                        {
                          title: "12 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "N/Local", dataIndex: "12_month_local" },
                            { title: "GF", dataIndex: "12_month_gf" },
                            { title: "F", dataIndex: "12_month_f" },
                          ],
                        },
                      ]}
                      list={list1}
                    />
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text
                      style={{
                        padding: "10px 50px 0",
                        fontFamily: "calibri-bold",
                        fontSize: 12,
                      }}
                    >
                      {`IV.  MATERIELS RECUS`}
                    </Text>
                    <Text
                      style={{
                        padding: "10px 50px 0",
                        fontFamily: "calibri-bold",
                        fontSize: 12,
                      }}
                    >
                      {`DATE : ${date}`}
                    </Text>
                    <Text
                      style={{
                        padding: "10px 50px 0",
                        fontFamily: "calibri-bold",
                        fontSize: 12,
                      }}
                    >
                      {`SOURCE : ${center?.getMetaValue(
                        "materialReceivedSoucre",
                        false,
                        plug
                      )}`}
                    </Text>
                  </View>
                  <View style={{ border: "1px solid #000", borderTop: "none" }}>
                    <TablePdfView
                      columns={[
                        { title: "N°", dataIndex: "range" },
                        { title: "Désignation", dataIndex: "designation" },
                        { title: "Nombre", dataIndex: "count" },
                        { title: "Motif", dataIndex: "motif" },
                        { title: "Observation", dataIndex: "observation" },
                      ]}
                      list={list2}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 16,
                      textAlign: "center",
                      fontFamily: "calibri-bold",
                      margin: "10px 0 0",
                    }}
                  >
                    {`STATISTIQUE DE NOUVEAUX INSCRITS`}
                  </Text>
                  <View
                    style={{
                      border: "1px solid #000",
                      borderTop: "none",
                      margin: "10px 0",
                    }}
                  >
                    <TablePdfView
                      columns={[
                        {
                          title: "14 JOURS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "14_day_g" },
                            { title: "F", dataIndex: "14_day_f" },
                          ],
                        },
                        {
                          title: "1 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "1_month_g" },
                            { title: "F", dataIndex: "1_month_f" },
                          ],
                        },
                        {
                          title: "3 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "3_month_g" },
                            { title: "F", dataIndex: "3_month_f" },
                          ],
                        },
                        {
                          title: "6 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "6_month_g" },
                            { title: "F", dataIndex: "6_month_f" },
                          ],
                        },
                        {
                          title: "STAGE",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "stage_g" },
                            { title: "F", dataIndex: "stage_f" },
                          ],
                        },
                      ]}
                      list={list3}
                    />
                  </View>
                  <View
                    style={{
                      border: "1px solid #000",
                      borderTop: "none",
                      margin: "10px 0",
                    }}
                  >
                    <TablePdfView
                      columns={[
                        {
                          title: "12 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "12_month_g" },
                            { title: "F", dataIndex: "12_month_f" },
                          ],
                        },
                        {
                          title: "24 MOIS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "24_month_g" },
                            { title: "F", dataIndex: "24_month_f" },
                          ],
                        },
                        {
                          title: "EMPLOYEURS",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "employeurs_g" },
                            { title: "F", dataIndex: "employeurs_f" },
                          ],
                        },
                        {
                          title: "EMPLOYES",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "employes_g" },
                            { title: "F", dataIndex: "employes_f" },
                          ],
                        },
                        {
                          title: "STAGE",
                          dataIndex: "",
                          children: [
                            { title: "G", dataIndex: "stage_g" },
                            { title: "F", dataIndex: "stage_f" },
                          ],
                        },
                      ]}
                      list={list4}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", alignItems: "stretch" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Pour le remplissage`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Le Chef d’Etablissement`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`VISA DU DIPROF`}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: "calibri",
                        textAlign: "center",
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Fait à ${plug.province}, le ${dateHelpers.getDate(
                        plug.createadAt,
                        "DD/MMM/YYYY"
                      )}`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Pour la vérification`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Collecteur des données`}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header: React.FC<{ list: any[] }> = ({ list }) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}
    >
      <View style={{ alignItems: "center", flex: 1, border: "2px solid #000" }}>
        <Text style={[styles.logoCountry]}>
          {`République Démocratique du Congo`}
        </Text>
        <Text style={[styles.logoHeader]}>{`Ministère de la Formation`}</Text>
        <Text style={[styles.logoHeader]}>{`Professionnelle et Métiers`}</Text>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "80px", textAlign: "center" }}
        />
        <Text style={[styles.logoHeader]}>
          {`Direction Statistiques Scolaires`}
        </Text>
        <Text style={[styles.logoHeader]}>{`DSS`}</Text>
      </View>
      <View
        style={{
          flex: 1,
          border: "2px solid #000",
          justifyContent: "center",
          alignContent: "center",
          padding: "0 5px",
        }}
      >
        {list.map((p, i) => {
          return (
            <ItemView
              label={p.label}
              value={p.value}
              key={`plug_header_${i}`}
            />
          );
        })}
      </View>
    </View>
  );
};

const TitleView: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
