import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { TableView } from "./table";
import CenterHelper from "../../utils/helpers/centerHelper";
import { centerMeta } from "../../utils/helpers/meta";
import { ItemTabView } from "./building/item";
import { Identification } from "./building/identification";
import { TablePdfView } from "../tablePDF";
import { isArray, isEmpty } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { ItemView } from "./center/itemView";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

const bgColor = "#dcdcdc";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
  logoCountry: {
    fontFamily: "calibri-italic",
    fontSize: 11,
    textAlign: "center",
  },
  logoHeader: {
    fontFamily: "calibri-bold",
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
  },
  title1: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  },
  title2: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    //height:"auto",
    padding: "1px",
    flex: 1,
  },
  value1: {
    fontSize: 11,
    fontFamily: "calibri",
    padding: 1,
    minHeight: "15px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    color: "#000",
  },
  view1: {
    width: "15%",
    borderRight: "1px solid #000",
  },
  view2: {
    borderRight: "1px solid #000",
    height: "100%",
  },
});

export function MovementFilePDF() {
  const plug = useSelector((state: RootState) => state.center.plug);
  const [center, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({}, "learnerPlugMetas")
  );
  const [headers, setHeaders] = React.useState<any[]>([]);
  const [list1, setList1] = React.useState<any[]>([]);
  const [element1, setElement1] = React.useState<any[]>([]);
  const [element2, setElement2] = React.useState<any[]>([]);
  const [element3, setElement3] = React.useState<any[]>([]);
  const [element4, setElement4] = React.useState<any[]>([]);
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    const c = new CenterHelper(plug, "learnerPlugMetas");
    setCenter(c);
    let elements = c.getMetaValue("states", false, plug);
    setList1(isEmpty(elements) ? [] : elements);
    const dt = c.getMetaValue("materialReceivedDate", false, plug);
    if (!isEmpty(dt)) {
      setDate(dateHelpers.getDate(dt, "dddd DD MMMM YYYY"));
    }
    setHeaderElements(c);
    setPart1(c);
    setPart2(c);
    setPart3(c);
  }, [plug]);

  function setHeaderElements(c: CenterHelper) {
    let list: any[] = [];
    list.push({
      label: "PROVINCE :",
      value: c.getMetaValue("province", false, plug),
    });
    list.push({
      label: "S/div  :",
      value: c.getMetaValue("subDivision", false, plug),
    });
    list.push({
      label: "RESEAU :",
      value: c.getMetaValue("network", false, plug),
    });
    list.push({
      label: "ETABLISSEMENT :",
      value: c.getMetaValue("center", false, plug),
    });
    list.push({
      label: "FILIERE :",
      value: c.getMetaValue("sector", false, plug),
    });
    list.push({
      label: "NIVEAU :",
      value: c.getMetaValue("lvel", false, plug),
    });
    const year1 = c.getMetaValue("year1", false, plug);
    const year2 = c.getMetaValue("year2", false, plug);
    if (!isEmpty(year1) && !isEmpty(year2)) {
      list.push({ label: "ANNEE DE FORMATION :", value: `${year1}-${year2}` });
    } else {
      list.push({ label: "ANNEE DE FORMATION :", value: `` });
    }
    setHeaders(list);
  }

  function setPart1(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "1. NOM: ", value: plug.firstName });
    list.push({
      label: "2. POST-NOM: ",
      value: plug.postName,
    });
    list.push({
      label: "3. PRENOM: ",
      value: plug.firstName,
    });
    list.push({
      label: "4. LIEU DE NAISSANCE: ",
      value: plug.birthPlace,
    });
    list.push({
      label: "5. DATE DE NAISSANCE: ",
      value: isEmpty(plug.birthDate)
        ? ""
        : dateHelpers.getDate(plug.birthDate, "DD/MM/YYYY"),
    });
    list.push({
      label: "6. NATIONALITE: ",
      value: c.getMetaValue("nationality", false, plug),
    });
    list.push({
      label: "7. NOM DU PERE :",
      value: c.getMetaValue("fatherName", false, plug),
    });
    list.push({
      label: "8. PROFESSION:",
      value: c.getMetaValue("fatherWork", false, plug),
    });
    list.push({
      label: "9. NOM DE LA MERE:",
      value: c.getMetaValue("montherName", false, plug),
    });
    list.push({
      label: "10. PROFESSION:",
      value: c.getMetaValue("montherWork", false, plug),
    });
    list.push({
      label: "11. ADRESSE DES PARENTS:",
      value: c.getMetaValue("parentAddress", false, plug),
    });
    list.push({
      label: "12. .PROVINCE D’ORIGINE:",
      value: c.getMetaValue("province", false, plug),
    });
    list.push({
      label: "13. .DISTRICT D’ORIGINE:",
      value: c.getMetaValue("district", false, plug),
    });
    list.push({
      label: "14. TERRITOIRE D’ORIGINE:",
      value: c.getMetaValue("territory", false, plug),
    });
    list.push({
      label: "15. SECTEUR D’ORIGINE:",
      value: c.getMetaValue("originSector", false, plug),
    });
    list.push({
      label: "16. VILLAGE D’ORIGINE:",
      value: c.getMetaValue("village", false, plug),
    });
    setElement1(list);
  }

  function setPart2(c: CenterHelper) {
    let list: any[] = [];
    const languages = c.getMetaValue("languages", false, plug) ?? [];
    let lang = "";
    languages.forEach((p: string, i: number) => {
      if (i > 0) {
        lang += ",";
      }
      lang += p;
    });
    list.push({
      label: "17. LANGUES PARLEES :",
      value: lang,
    });
    list.push({
      label: "18. ADRESSE DE L’ELEVE  :",
      value: c.getMetaValue("oldAddress", false, plug),
    });
    list.push({
      label: "19. TUTEUR :",
      value: c.getMetaValue("oldTutorName", false, plug),
    });
    list.push({
      label: "20. N° Tel. :",
      value: c.getMetaValue("oldTutorPhone", false, plug),
    });
    list.push({
      label: "21. PROFESSION :",
      value: c.getMetaValue("oldTutorWork", false, plug),
    });
    list.push({
      label: "22. ADRESSE  :",
      value: c.getMetaValue("neighboringEstablishments", false, plug),
    });
    setElement2(list);
  }

  function setPart3(c: CenterHelper) {
    let list: any[] = [];
    list.push({
      label: "1. Etat de santé :",
      value: c.getMetaValue("health", false, plug),
    });
    list.push({
      label: "2. Aptitudes intellectuelles: ",
      value: c.getMetaValue("intellectualAptitude", false, plug),
    });
    list.push({
      label: "3. Intérêts :",
      value: c.getMetaValue("interests", false, plug),
    });
    setElement3(list);
  }

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DSS" creator="DEV">
            <Page size="A4" style={styles.page}>
              <View>
                <View>
                  <Header list={headers} />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "5px 0",
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px",
                        fontFamily: "calibri-bold",
                        fontSize: 20,
                        textDecoration: "underline",
                      }}
                    >
                      {`FICHE DE MOUVEMENT DE L'APPRENANT `}
                    </Text>
                  </View>
                  <TitleView title="I.  IDENTIFICATION" key="part1" />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "0",
                      width: 300,
                    }}
                  >
                    <ItemView label="TYPE:" value="dffd" />
                  </View>
                  <View style={{ padding: "0 20px" }}>
                    <Text style={{ fontSize: 11, fontFamily: "calibri" }}>
                      {`NUMERO D'IDENTIFICATION`}
                    </Text>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flex: 1 }}>
                        <Text>
                          {center.getMetaValue("registerNumber", false, plug)}
                        </Text>
                      </View>
                      <View style={{ width: 200 }}>
                        <ItemView
                          label="C:"
                          value={center.getMetaValue("c", false, plug)}
                        />
                        <ItemView
                          label="E:"
                          value={center.getMetaValue("e", false, plug)}
                        />
                      </View>
                    </View>
                    <View style={{ flexDirection: "row", gap: 8 }}>
                      <View style={{ flex: 1 }}>
                        {element1.map((p, i) => (
                          <ItemView
                            label={p.label}
                            value={p.value}
                            key={`part1_${i}`}
                          />
                        ))}
                      </View>
                      <View style={{ flex: 1 }}>
                        {element2.map((p, i) => (
                          <ItemView
                            label={p.label}
                            value={p.value}
                            key={`part1_${i}`}
                          />
                        ))}
                      </View>
                    </View>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: "calibri-bold",
                      textAlign: "center",
                      padding: 5,
                    }}
                  >
                    {`AUTRES INFORMATIONS SUR L'APPRENANT`}
                  </Text>
                  <View style={{ padding: "0 20px", flexDirection: "row" }}>
                    <View style={{ flex: 1 }}>
                      {element3.map((p, i) => (
                        <ItemView
                          label={p.label}
                          value={p.value}
                          key={`part3_${i}`}
                        />
                      ))}
                    </View>
                    <View style={{ width: 250 }}></View>
                  </View>
                  <TitleView title="II. AUTORISATION DE MOUVEMENT " />
                  <View>
                    <Text style={{ fontSize: 11, fontFamily: "calibri" }}>
                      {`La Direction de l’école, le parent ou le tuteur entendu, l’apprenant mieux identifiée ci-dessus est autorisé(e) à changer d’établissement.`}
                    </Text>
                    <ItemView
                      label="Motif: "
                      value={center.getMetaValue("motif", false, plug)}
                    />
                  </View>
                  <TitleView title="III. LE PARCOURS DE FORMATION EFFECTUE PAR L’APPRENANT" />
                  <View style={{ border: "1px solid #000", borderTop: "none" }}>
                    <TablePdfView
                      columns={[
                        {
                          title: `Année de formation 20… 20…`,
                          dataIndex: "year",
                        },
                        {
                          title: "Nom et code de l’établissement",
                          dataIndex: "center",
                        },
                        { title: "Mention obtenu", dataIndex: "mention" },
                      ]}
                      list={list1}
                    />
                  </View>
                </View>
                <View style={{ flexDirection: "row", alignItems: "stretch" }}>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Pour le remplissage`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Le Chef d’Etablissement`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`VISA DU DIPROF`}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: "calibri",
                        textAlign: "center",
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Fait à ${plug.province}, le ${dateHelpers.getDate(
                        plug.createadAt,
                        "DD/MMM/YYYY"
                      )}`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Pour la vérification`}
                    </Text>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Collecteur des données`}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header: React.FC<{ list: any[] }> = ({ list }) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}
    >
      <View style={{ alignItems: "center", flex: 1, border: "2px solid #000" }}>
        <Text style={[styles.logoCountry]}>
          {`République Démocratique du Congo`}
        </Text>
        <Text style={[styles.logoHeader]}>{`Ministère de la Formation`}</Text>
        <Text style={[styles.logoHeader]}>{`Professionnelle et Métiers`}</Text>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "80px", textAlign: "center" }}
        />
        <Text style={[styles.logoHeader]}>
          {`Direction Statistiques Scolaires`}
        </Text>
        <Text style={[styles.logoHeader]}>{`DSS`}</Text>
      </View>
      <View
        style={{
          flex: 1,
          border: "2px solid #000",
          justifyContent: "center",
          alignContent: "center",
          padding: "0 5px",
        }}
      >
        {list.map((p, i) => {
          return (
            <ItemView
              label={p.label}
              value={p.value}
              key={`plug_header_${i}`}
            />
          );
        })}
      </View>
    </View>
  );
};

const TitleView: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
