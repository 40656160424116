import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  Font,
  StyleSheet,
} from "@react-pdf/renderer";
import { TableView } from "./table";
import CenterHelper from "../../utils/helpers/centerHelper";
import { centerMeta } from "../../utils/helpers/meta";
import { ItemTabView } from "./building/item";
import { Identification } from "./building/identification";
import { TablePdfView } from "../tablePDF";
import { isArray, isEmpty } from "lodash";
import dateHelpers from "../../utils/helpers/dateHelpers";
import { ItemView } from "./center/itemView";

Font.register({
  family: "calibri-bold-italic",
  src: require("../../assets/fonts/calibri/Calibri Bold Italic.ttf"),
});
Font.register({
  family: "calibri-bold",
  src: require("../../assets/fonts/calibri/Calibri Bold.TTF"),
});
Font.register({
  family: "calibri-italic",
  src: require("../../assets/fonts/calibri/Calibri Italic.ttf"),
});
Font.register({
  family: "calibri-light-italic",
  src: require("../../assets/fonts/calibri/Calibri Light Italic.ttf"),
});
Font.register({
  family: "calibri-light",
  src: require("../../assets/fonts/calibri/Calibri Light.ttf"),
});
Font.register({
  family: "calibri",
  src: require("../../assets/fonts/calibri/Calibri Regular.ttf"),
});

const bgColor = "#dcdcdc";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: "20px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "red",
    border: "solid 5px yellow",
  },
  title: {
    textTransform: "uppercase",
    fontSize: 11,
    padding: "10px 0",
    fontFamily: "calibri-bold",
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    borderBottom: "1px solid #dadada",
  },
  rowItem: {
    display: "flex",
    flex: 1,
    fontSize: "14px",
    padding: "8px 0",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    backgroundColor: "#ececec",
  },
  logoCountry: {
    fontFamily: "calibri-italic",
    fontSize: 11,
    textAlign: "center",
  },
  logoHeader: {
    fontFamily: "calibri-bold",
    fontSize: 16,
    textTransform: "uppercase",
    textAlign: "center",
  },
  title1: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
  },
  title2: {
    fontSize: 11,
    fontFamily: "calibri",
    borderBottom: "1px solid #000",
    backgroundColor: bgColor,
    textAlign: "center",
    minHeight: "auto",
    color: "#000",
    //height:"auto",
    padding: "1px",
    flex: 1,
  },
  value1: {
    fontSize: 11,
    fontFamily: "calibri",
    padding: 1,
    minHeight: "15px",
    height: "auto",
    display: "flex",
    justifyContent: "center",
    color: "#000",
  },
  view1: {
    width: "15%",
    borderRight: "1px solid #000",
  },
  view2: {
    borderRight: "1px solid #000",
    height: "100%",
  },
});

export function NewLearnerPDF() {
  const plug = useSelector((state: RootState) => state.center.plug);
  const [center, setCenter] = React.useState<CenterHelper>(
    new CenterHelper({})
  );
  const [headers, setHeaders] = React.useState<any[]>([]);
  const [list1, setList1] = React.useState<any[]>([]);

  React.useEffect(() => {
    const c = new CenterHelper(plug, "centerPlugMetas");
    setCenter(c);
    let elements = c.getMetaValue("learners", false, plug);
    setList1(isEmpty(elements) ? [] : elements);
    setHeaderElements(c);
  }, [plug]);

  function setHeaderElements(c: CenterHelper) {
    let list: any[] = [];
    list.push({ label: "PROVINCE :", value: plug.province });
    list.push({
      label: "DIVISION  :",
      value: c.getMetaValue("division", false, plug),
    });
    list.push({
      label: "SOUS-DIVISION  :",
      value: c.getMetaValue("subProvince", false, plug),
    });
    list.push({
      label: "ECOLE :",
      value: c.getMetaValue("school", false, plug),
    });
    list.push({
      label: "CENTRE :",
      value: plug.name,
    });
    list.push({
      label: "RESEAU  :",
      value: c.getMetaValue("network", false, plug),
    });
    list.push({
      label: "CYCLE :",
      value: c.getMetaValue("cycle", false, plug),
    });
    const year1 = c.getMetaValue("year1", false, plug);
    const year2 = c.getMetaValue("year2", false, plug);
    if (!isEmpty(year1) && !isEmpty(year2)) {
      list.push({ label: "ANNEE DE FORMATION :", value: `${year1}-${year2}` });
    } else {
      list.push({ label: "ANNEE DE FORMATION :", value: `` });
    }
    setHeaders(list);
  }

  return (
    <React.Fragment>
      <section className="container">
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document author="DSS" creator="DEV">
            <Page size="A4" style={styles.page}>
              <View>
                <View>
                  <Header list={headers} />
                  <View
                    style={{
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <Text
                      style={{
                        border: "none",
                        textDecoration: "underline",
                        fontFamily: "calibri-bold",
                        padding: "5px",
                      }}
                    >
                      {`FICHE D’ENREGISTREMENT DES NOUVEAUX INSCRITS`}
                    </Text>
                  </View>
                  <View style={{ border: "1px solid #000", borderTop: "none" }}>
                    <TablePdfView
                      columns={[
                        {
                          title: "N°",
                          dataIndex: "range",
                        },
                        {
                          title: "N° PERMANENT",
                          dataIndex: "registerNumber",
                        },
                        {
                          title: "NOM, POSTNOM ET PRENOM",
                          dataIndex: "fullName",
                        },
                        { title: "SEXE", dataIndex: "sex" },
                        { title: "NIVEAU", dataIndex: "level" },
                        { title: "FILIERE", dataIndex: "sector" },
                        { title: "%", dataIndex: "percent" },
                        { title: "CENTRE DE PROVENANCE", dataIndex: "center" },
                        { title: "ADRESSE", dataIndex: "address" },
                        { title: "DOCUMENT DEPOSE", dataIndex: "doc" },
                        { title: "RESULTAT D’ENQUETE", dataIndex: "result" },
                      ]}
                      list={list1}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "stretch",
                    marginTop: 20,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Visa du Chef d’Etablissement `}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 13,
                        fontFamily: "calibri",
                        textAlign: "center",
                        padding: "5px 0 10px",
                      }}
                    >
                      {`Fait à ${plug.province}, le ${dateHelpers.getDate(
                        plug.createadAt,
                        "DD/MMM/YYYY"
                      )}`}
                    </Text>
                  </View>
                </View>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </section>
    </React.Fragment>
  );
}

const Header: React.FC<{ list: any[] }> = ({ list }) => {
  return (
    <View
      style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}
    >
      <View style={{ alignItems: "center", flex: 1, border: "none" }}>
        <Text style={[styles.logoCountry]}>
          {`République Démocratique du Congo`}
        </Text>
        <Text style={[styles.logoHeader]}>{`Ministère de la Formation`}</Text>
        <Text style={[styles.logoHeader]}>{`Professionnelle et Métiers`}</Text>
        <Image
          src={require("../../assets/images/armoirie.png")}
          style={{ width: "80px", textAlign: "center" }}
        />
        <Text style={[styles.logoHeader]}>
          {`Direction Statistiques Scolaires`}
        </Text>
        <Text style={[styles.logoHeader]}>{`DSS`}</Text>
      </View>
      <View
        style={{
          flex: 1,
          border: "none",
          justifyContent: "center",
          alignContent: "center",
          padding: "0 5px",
        }}
      >
        {list.map((p, i) => {
          return (
            <ItemView
              label={p.label}
              value={p.value}
              key={`plug_header_${i}`}
            />
          );
        })}
      </View>
    </View>
  );
};

const TitleView: React.FC<{ title: string }> = ({ title }) => {
  return (
    <View>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
