import { routeInterface } from "../routes";
import { CreateCenterScreen } from "../screens/center/create";
import { CenterView } from "../screens/center/view";
import { CenterScreen } from "../screens/centers";
import { MapScreen } from "../screens/map";
export const centerRoutes: routeInterface[] = [
  {
    component: CreateCenterScreen,
    icon: "fa-solid fa-school",
    isMenu: false,
    label: "Ajouter un établissement",
    layout: "/admin",
    name: "create_center",
    path: "/center/create",
    subscribeable: false,
  },
  {
    component: CenterView,
    icon: "",
    isMenu: false,
    label: "centre",
    layout: "/admin",
    name: "center-view",
    path: "/center/:id",
    subscribeable: false,
  },
  {
    component: CenterScreen,
    icon: `fa-sharp fa-solid fa-buildings`,
    isMenu: true,
    label: "Tous les établissements",
    layout: "/admin",
    name: "center-screen",
    path: "/centers",
    subscribeable: false,
  },
  {
    component: MapScreen,
    icon: "fa-solid fa-globe",
    isMenu: true,
    label: "Cartographie",
    layout: "/admin",
    name: "create_map",
    path: "/maps",
    subscribeable: false,
  },
];
