import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link, useParams } from "react-router-dom";
import indicatorService from "../../services/indicatorService";
import { setIndicator } from "../../redux/indicatorReducer";
import Chart from "react-apexcharts";
import {
  colorAccent,
  colorDanger,
  colorPrimary,
} from "../../utils/themes/colors";
import { Button, Card, Spin, Typography } from "antd";


export function ShowGraphicScreen(){

    const indicator = useSelector((state:RootState)=>state.indicator.current);
    const [loading, setLoading] = React.useState(false);
    const [labels, setLabels] = React.useState<string[]>([]);
    const [series, setSeries] = React.useState<any[]>([]);
    const dispatch = useDispatch();
    const params = useParams();
    const onRetrieve = async() =>{
        const uid = params.uid as string;
        await indicatorService.retrieve(uid).then(response=>{
            const data = response.data;
            const vs = data?.series ?? [] 
            setSeries([...vs]);
            setLabels(data?.labels ?? []);
            dispatch(setIndicator(data));
        }).catch(()=>{})
    }

    const onInit = React.useCallback(async()=>{
        dispatch(setIndicator({}))
        setLoading(true);
        await onRetrieve();
        setLoading(false);
    },[params])

    React.useEffect(()=>{
        onInit();
    },[params])

    return(
        <main className="main yc_container_main">
            <Spin spinning={loading}>
            <Card hoverable title={indicator.title} style={{minHeight:"100vh",margin:"10px auto"}}>
                <Typography.Paragraph style={{textAlign:"justify"}}>
                    {indicator.note}
                </Typography.Paragraph>
      <div style={{height:"100%"}}>
          {(indicator.chart !== "table") && (<Chart
            type={
              indicator.chart === "table" ? "area" : indicator.chart ?? "area"
            }
            options={{
              chart: {
                type:
                  indicator.chart === "table"
                    ? "area"
                    : indicator.chart ?? "area",
                stacked: true,
              },
              colors: [colorPrimary, colorAccent, colorDanger, "#AAFF00"],
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: "monotoneCubic",
              },
              fill: {
                type: "gradient",
                gradient: {
                  opacityFrom: 0.9,
                  opacityTo: 0.8,
                },
              },
              legend: {
                position: "top",
                horizontalAlign: "left",
              },
              xaxis: {
                //type: 'datetime'
              },
              labels,
            }}
            series={series}
          />)}
          {(indicator.chart === "table" && !loading) && (
            <table className="__table">
                <thead>
                    <tr>
                        <td>{`Division`}</td>
                        {series.map((p,i)=>(
                            <td key={`td_header_${i}`}>{p.name}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {labels.map((p,i)=>(
                        <tr key={`item_${i}`}>
                            <td>{p}</td>
                            {series.map((q,j)=>(
                                <td key={`td_body_${i}_${j}`}>
                                    {q.data[i] ?? 0}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
          )}
      </div>

      <div style={{textAlign:"center",padding:10}}>
      <Button>
        <Link to={`/admin/graphics`}>
        {`Retouner à la liste`}
        </Link>
      </Button>
      </div>
    </Card>
            </Spin>
        </main>
    )
}