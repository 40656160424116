import React from "react";
//Map component Component from library
import { GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import * as lib from "geolib";
import { random } from "lodash";
import { Button } from "antd";
import { MapProvider } from "./provider";
import pin from "../../assets/images/fill.svg";
import { Link } from "react-router-dom";

//Map's styling
export const defaultMapContainerStyle = {
  width: "100%",
  height: "90%",
  margin: "auto",
};

const defaultMapOptions: google.maps.MapOptions = {
  zoomControl: true,
  tilt: 0,
  gestureHandling: "auto",
  mapTypeId: "satellite",
  //mapTypeId: google.maps.MapTypeId.SATELLITE,
  //mapTypeId: window.google?.maps?.MapTypeId.,
};

const defaultMapZoom = 18;

export const MapComponent: React.FC<{
  data: { lat: number; lng: number; item?: any }[];
}> = ({ data }) => {
  const [marker, setMarker] = React.useState<google.maps.Marker | undefined>();
  const [center, setCenter] = React.useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [current, setCurrent] = React.useState<any>({});
  const [currentMarkerIndex, setCurrentMarkerIndex] = React.useState<any>();

  const [infoWindowShown, setInfoWindowShown] = React.useState(false);

  const handleMarkerClick = React.useCallback((item?: any, index?: any) => {
    const types = [
      "les villas et les immeubles autres qu’à étages",
      "les immeubles à étages",
      "les appartements",
      "les autres constructions",
    ];
    const rd = item?.rd ?? 0;
    const elem = types[rd] ?? "";
    const p = { ...item };
    p.code = elem;
    if (rd === 1) {
      p.floors = random(1, 17, false);
    } else if (rd === 2) {
      p.apartments = random(2, 25, false);
    }
    setCurrent(p);
    setCurrentMarkerIndex(index);
    setInfoWindowShown((isShown) => !isShown);
  }, []);
  const handleClose = React.useCallback(() => {
    setInfoWindowShown(false);
    setCurrentMarkerIndex(undefined);
  }, []);

  React.useEffect(() => {
    onData();
  }, [data]);

  const onData = React.useCallback(() => {
    const coords = data.map((p) => {
      return {
        longitude: p.lng,
        latitude: p.lat,
      };
    });
    const c = lib.getCenter(coords);
    if (c) {
      const v = { lat: c.latitude, lng: c.longitude };
      console.log('V',v);
      setCenter(v);
    }
  }, [data]);

  return (
    //@ts-ignore
    <MapProvider>
      <div style={{ height: "100vh", padding: 0 }}>
        <GoogleMap
          mapContainerStyle={defaultMapContainerStyle}
          center={{lat:center.lat,lng:center.lng}}
          zoom={defaultMapZoom}
          options={defaultMapOptions}
        >
          {data.map((p, i) => {
            let url = require("../../assets/images/fill.svg");
            return (
              <Marker
                key={`google_marker_${i}`}
                position={{ lat: p.lat, lng: p.lng }}
                ref={(p) => setMarker(p?.marker)}
                onClick={() => handleMarkerClick(p.item, i)}
              >
                {infoWindowShown && currentMarkerIndex === i && (
                  <InfoWindow
                    anchor={marker}
                    onCloseClick={handleClose}
                    options={{}}
                  >
                    <div>
                      <img
                        //src="/assets/images/paradise.jpg"
                        src={require('../../assets/images/min.png')}
                        alt=""
                        style={{
                          width: "100%",
                          height: 100,
                          objectFit: "cover",
                        }}
                      />
                      <div style={{ paddingTop: "5px" }}>
                        <div style={{ textAlign: "center", padding: 4,fontWeight:"bold",fontSize:18 }}>
                          <span>{current?.name ?? ""}</span>
                        </div>
                        <div className="map-item">
                          <span className="title">Province : </span>
                          <span className="content">
                            {`${current?.subDivision?.division?.name ?? ""}, ${
                              current?.subDivision?.name ?? ""
                            }`}
                          </span>
                        </div>
                        <div className="map-item">
                          <span className="title">Apprenants : </span>
                          <span className="content">
                            {`${current?.leaners?.length ?? 0}`}
                          </span>
                        </div>
                        <div className="map-item">
                          <span className="title">Formateurs :</span>
                          <span className="content">
                            {`${current?.formers?.length ?? 0} `}
                          </span>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Button type="link">
                            <Link to={`/admin/center/${p?.item?.id ?? ''}`}>
                            {`Afficher l'établissement`}
                            </Link>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
        </GoogleMap>
      </div>
    </MapProvider>
  );
};
