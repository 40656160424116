import { indicatorType } from "./types";

export const sectorIndicator: indicatorType = {
  label: "Filières",
  key: "sector",
  endpoint: "/sectors/",
  description: "lorem ipsum",
  image: require("../../assets/images/indicator/translator-subject-svgrepo-com.png"),
  values: [
    {
      key: "center",
      type: "array",
      variable: "center",
      label: "Nombre de centre",
    },
    {
      key: "leaner",
      type: "array",
      variable: "leaners",
      label: "Nombre d'apprenants",
    },
    {
      key: "book",
      type: "array",
      variable: "books",
      label: "Nombre de livre",
    },
    {
      key: "total",
      type: "array",
      variable: "",
      label: "Total",
    },
  ],
  categories: [
    {
      key: "month",
      label: "Mois",
      filter: "createdAt",
    },
    {
      key: "year",
      label: "Année",
      filter: "createdAt",
    },
    {
      key: "day",
      label: "Jour",
      filter: "createdAt",
    },
    {
      key: "name",
      label: "Nom",
      filter: "name",
    },
    {
      key: "type",
      label: "Filière ou métier",
      filter: "type",
    },
  ],
};
