import { useFormik } from "formik";
import React from "react";
import { DillSelect } from "../../components/input";
import { indicators } from "../../utils/helpers/indicators";
import { Card, Divider, List, Typography } from "antd";
import { colorPrimary } from "../../utils/themes/colors";

export function CompositionStep(props: {
  type: string;
  formik: any;
  setIndicators: (v: any[]) => void;
  indicators: any[];
}) {
  const [values, setValues] = React.useState<any[]>([]);
  const [categories, setCategories] = React.useState<any[]>([]);
  const { formik } = props;

  const onInit = React.useCallback(() => {
    const list = [...indicators];
    const vs: any[] = list.find((p) => p.key === props.type)?.values ?? [];
    setValues(vs);
    const cats: any[] =
      list.find((p) => p.key === props.type)?.categories ?? [];
    setCategories(
      cats.map((p) => {
        return {
          value: p.key,
          title: p.label,
        };
      })
    );
  }, [props.type]);

  React.useEffect(() => {
    onInit();
  }, [props.type]);

  function handleChoice(v: any) {
    let list = [...props.indicators];
    const f = list.findIndex((p) => p.key === v.key);
    if (f === -1) {
      list.push(v);
    } else {
      list = list.filter((p) => p.key !== v.key);
    }
    props.setIndicators(list);
  }

  const renderItem = (item: any, index: number) => {
    const exist = props.indicators.findIndex((p) => p.key === item.key) !== -1;

    return (
      <List.Item onClick={() => handleChoice(item)} key={`indicator_${index}`}>
        <Card
          bodyStyle={{ color: exist ? "#FFF" : undefined }}
          headStyle={{ color: exist ? "#FFF" : undefined }}
          title={`Forme d'indicateur ${index + 1}`}
          hoverable
          style={{
            width: "100%",
            background: exist ? colorPrimary : undefined,
          }}
        >
          <Card.Meta
            style={{ color: exist ? "#FFF" : undefined }}
            description={item.label}
          />
        </Card>
      </List.Item>
    );
  };

  return (
    <section>
      <div>
        <Typography.Title
          level={2}
          style={{ fontSize: 20, textAlign: "center" }}
        >
          {`La variable dépendante ou les valeurs mesurées`}
        </Typography.Title>
        <Divider />
        <List
          dataSource={values}
          renderItem={renderItem}
          grid={{ gutter: 16, column: 3, lg: 3, xs: 1, md: 2 }}
        />
      </div>
    </section>
  );
}
