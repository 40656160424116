export const chartTypes = [
  {
    type: "area",
    label: "Graphique en aire",
    image: require("../assets/images/graphics/area-chart-svgrepo-com.png"),
  },
  {
    type: "line",
    label: "Graphiques en ligne",
    image: require("../assets/images/graphics/line-chart-up-01-svgrepo-com.png"),
  },
  {
    type: "bar",
    label: "Histogramme",
    image: require("../assets/images/graphics/histogram-svgrepo-com.png"),
  },
  {
    type: "pie",
    label: "Diagramme circulaire",
    image: require("../assets/images/graphics/pie-chart-piece-svgrepo-com.png"),
  },
  {
    type: "polarArea",
    label: "Zone polaire",
    image: require("../assets/images/graphics/polar-plot-svgrepo-com.png"),
  },
  {
    type: "bubble",
    label: "Graphique à bulles",
    image: require("../assets/images/graphics/chart-scatter-bubble-svgrepo-com.png"),
  },
  {
    type: "table",
    label: "Tableau de données",
    image: require("../assets/images/graphics/table-svgrepo-com.png"),
  },
];
