import { Button, Card, Col, List, Row, Typography } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../redux/store";
import indicatorService from "../services/indicatorService";
import { setAllIndicators } from "../redux/indicatorReducer";

export function GraphicScreen() {
  const navigate = useNavigate();
  const indicators = useSelector((state:RootState)=>state.indicator.all);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);

  const onInit =async() =>{
    await indicatorService.getByKey(``).then(response=>{
      const data = indicatorService.getData(response);
      dispatch(setAllIndicators(data));
    }).catch(()=>{});
  }

  React.useEffect(()=>{
    async function onData(){
      setLoading(indicators.length === 0);
      await onInit();
      setLoading(false);
    }
    onData();
  },[])

  const renderItem = (item: any, index: number) => {
    const type = item.chartType;
    let url = require("../assets/images/graphics/pie-chart-svgrepo-com.png");
    switch (type) {
      case "area":
        url = require('../assets/images/graphics/area-chart-svgrepo-com.png');
        break;
      case "histogram":
        url = require('../assets/images/graphics/histogram-svgrepo-com.png');
        break;
      case "line":
        url = require('../assets/images/graphics/line-chart-up-01-svgrepo-com.png');
        break;
      case "table":
        url = require('../assets/images/graphics/table-svgrepo-com.png');
        break;
      case "bubble":
        url = require('../assets/images/graphics/chart-scatter-bubble-svgrepo-com.png');
        break;
    
      default:
        break;
    }
    return (
      <List.Item key={`graphic_${index}`} style={{ width: "100%" }}>
        <Card
          className="card__5"
          style={{ width: "100%" }}
          actions={[
            <Button type="text" key={`view_button`} onClick={()=>{
              navigate(`/admin/graphics/${item.id}`)
            }}>
              <i className="fa-solid fa-eye"></i>
            </Button>,
            <Button disabled type="text" key={`update_button`}>
              <i className="fa-solid fa-pen-to-square"></i>
            </Button>,
            <Button disabled danger type="text" key={`delete_button`}>
              <i className="fa-solid fa-trash"></i>
            </Button>,
          ]}
          hoverable
          cover={
            <img
              style={{ height: "170px", objectFit: "contain" }}
              src={url}
            />
          }
        >
          <Typography.Title level={2} style={{fontSize:18}}>
            {item.label}
          </Typography.Title>
          <Typography.Paragraph ellipsis={{rows:2}}>
            {item.note}
          </Typography.Paragraph>
        </Card>
      </List.Item>
    );
  };

  function handleNewIndicator() {
    navigate(`/admin/graphics/create`);
  }

  return (
    <main className="main yc_container_main">
      <Card style={{ margin: "10px 0" }}>
        <Row>
          <Col flex={1}>
            <Typography.Title>
              {`Les indicateurs statistiques`}
            </Typography.Title>
          </Col>
          <Col>
            <Button onClick={handleNewIndicator} type="primary">
              {`Nouvel indicateur`}
            </Button>
          </Col>
        </Row>
      </Card>
      <List
        renderItem={renderItem}
        dataSource={indicators} loading={loading}
        grid={{ gutter: 16, lg: 3,xl:3,xxl:3,md:2,sm:2, xs: 1, column: 1 }}
      />
    </main>
  );
}
