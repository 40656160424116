import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface mailData {
  current: any;
  list: any[];
  all: any[];
}

const initialState: mailData = {
  current: {},
  all: [],
  list: [],
};

export const indicatorSlice = createSlice({
  name: "indicator",
  initialState,
  reducers: {
    setIndicator: (state, action: PayloadAction<any>) => {
      state.current = action.payload;
    },
    setIndicators: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    setAllIndicators: (state, action: PayloadAction<any[]>) => {
      state.all = action.payload;
    },
  },
});
export const { setAllIndicators,setIndicator,setIndicators } =
  indicatorSlice.actions;
export default indicatorSlice.reducer;
