import { ThemeConfig } from "antd";
import { colorPrimary } from "./colors";

export const lightTheme:ThemeConfig = {
    token: {
      colorPrimary: colorPrimary,
    },
    components: {
      Input: {
        controlHeight: 52,
      },
      Button: {
        controlHeight: 40,
      },
      Select: {
        controlHeight: 40,
      },
      Radio: {
        controlHeight: 40,
      },
    },
  }