import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "./adminReducer";
import bookReducer from "./bookReducer";
import centerReducer from "./centerReducer";
import divisionReducer from "./divisionReducer";
import formerReducer from "./formerReducer";
import learnerReducer from "./learnerReducer";
import messageReducer from "./messageReducer";
import sectorReducer from "./sectorReducer";
import subDivisionReducer from "./subDivisionReducer";
import userReducer from "./userReducer";
import examReducer from "./examReducer";
import editionReducer from "./editionReducer";
import mailReducer from "./mailReducer";
import resultsReducer from "./resultsReducer";
import sheetReducer from "./sheetReducer";
import indicatorReducer from "./indicatorReducer";
const store = configureStore({
  reducer: {
    user: userReducer,
    center: centerReducer,
    division: divisionReducer,
    learner: learnerReducer,
    subDivision: subDivisionReducer,
    message: messageReducer,
    sector: sectorReducer,
    book: bookReducer,
    admin: adminReducer,
    former: formerReducer,
    exam: examReducer,
    edition: editionReducer,
    mail: mailReducer,
    result: resultsReducer,
    sheet: sheetReducer,
    indicator:indicatorReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
