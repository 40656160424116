import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { mapKey } from "../../utils/helpers/title";

const libraries = ["places", "drawing", "geometry"];

export function MapProvider({ children }: { children: React.ReactNode }) {
  // Load the Google Maps JavaScript API asynchronously
  const { isLoaded: scriptLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: mapKey,
    libraries: libraries as Libraries,
  });

  if (loadError) return <p>Encountered error while loading google maps</p>;

  if (!scriptLoaded) return <p>Map Script is loading ...</p>;

  // Return the children prop wrapped by this MapProvider component
  return children;
}
