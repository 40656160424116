import { Button, Card, Col, Modal, Row, Spin, Steps, notification } from "antd";
import React from "react";
import { DataTypeStep } from "./dataType";
import { isEmpty } from "lodash";
import { CompositionStep } from "./composition";
import { useFormik } from "formik";
import { DataStep } from "./data";
import { DataChart } from "./chart";
import { DiagramStep } from "./diagram";
import * as yup from 'yup';
import { indicators as collection } from "../../utils/helpers/indicators";
import indicatorService from "../../services/indicatorService";
import { useNavigate } from "react-router-dom";

export function CreateIndicatorScreen() {
  const [step, setStep] = React.useState(0);
  const [type, setType] = React.useState("");
  const [indicators, setIndicators] = React.useState<any[]>([]);
  const [data, setData] = React.useState<any[]>([]);
  const [show, setShow] = React.useState(false);
  const [chart, setChart] = React.useState<string | undefined>("area");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      value: "",
      category: "",
      values: [],
      restrictions: [],
    },
    onSubmit(values, formikHelpers) {},
  });

  const formikTitle = useFormik({
    initialValues:{
      title:"",
      note:"",
    },
    onSubmit(values, formikHelpers) {
        setStep(step + 1);
    },
    validationSchema:yup.object({
      title:yup.string().required('Champ requis'),
      note:yup.string(),
    })
  })

  function handleSubmit() {
    switch (step) {
      case 0:
        if (isEmpty(type)) {
          notification.error({
            message: "Aucun type de donnée sélectionné",
            description:
              "Vous n'avez pas choisi de type de données, vous ne pouvez pas progresser sans celui-ci.",
          });
          return;
        }
        setStep(step + 1);
        break;
      case 1:
        if (indicators.length === 0) {
          notification.error({
            message: "Aucun indicateur sélectionné",
            description:
              "Vous n'avez pas choisi d'indicateurs', vous ne pouvez pas progresser sans celui-ci.",
          });
          return;
        }
        setStep(step + 1);
        break;
      case 2:
        formikTitle.handleSubmit();
        break;
      case 3:
        //setShow(true);
        Modal.confirm({centered:true,title:"Confirmez et enregistrez",content:"Etes-vous sûrs de créer l'indicateur avec des informations renseignées?", okText:"Je confirme",cancelText:"Annuler",onOk(...args) {
            onSave();
        },})
        break;

      default:
        break;
    }
  }

  async function onSave() {
    const store:any = {label:formikTitle.values.title,note:formikTitle.values.note};
    const element = collection.find(p=>p.key === type);

        store.endpoint = element?.endpoint;
        store.properties = element?.properties;
        store.data = indicators;
        store.selections = data;
        store.chartType = chart;
        setLoading(true);
        await indicatorService.store(store).then(response=>{
          navigate(`/admin/graphics/${response.data.id}`);
        }).catch((reason)=>{
          const error = indicatorService.getError(reason);
          notification.error({message:"Erreur lors de l'enregistrement",description:error});
        })
        setLoading(false);
  }

  function handleReturn() {
    if (step > 0) {
      setStep(step - 1);
      setShow(false);
    }
  }

  return (
    <main
      className="main yc_container_main"
      style={{
        background: `linear-gradient(#F7F7F7AA,#F0F0F0BB),url(${require("../../assets/images/graphics/blank.jpg")})`,
        backgroundAttachment:"fixed",
      }}
    >
      {!show && (
        <Spin spinning={loading}>
        <div
          style={{
            display: "flex",
            minHeight: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="indicator__card" style={{ marginBottom: 10 }}>
            <Steps
              items={[
                { title: "Type de donnée" },
                { title: "Présentation de donnée" },
                { title: "Données à traiter" },
                { title: "Graphique" },
              ]}
              current={step}
            />
          </div>
          <Card className="card--1 indicator__card">
            {step === 0 && <DataTypeStep type={type} setType={setType} />}
            {step === 1 && (
              <CompositionStep
                indicators={indicators}
                setIndicators={setIndicators}
                formik={formik}
                type={type}
              />
            )}
            {step === 2 && (
              <DataStep formik={formikTitle} selections={data} setSelections={setData} type={type} />
            )}
            {step === 3 && <DiagramStep type={chart} setType={setChart} />}
            <div style={{ textAlign: "center" }}>
              <Row align="middle" gutter={16} justify="center">
                <Col>
                  <Button onClick={handleReturn}>{`Retourner`}</Button>
                </Col>
                <Col>
                  <Button onClick={handleSubmit} type="primary">
                    {step<3?`Suivant`:`Enregistrer`}
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
        </Spin>
      )}
      {show && (
        <DataChart
          chartType={chart}
          onQuit={handleReturn}
          type={type}
          indicators={indicators}
          selections={data}
        />
      )}
    </main>
  );
}
